import {mapActions, mapGetters} from "vuex";
import AOS from 'aos';

import 'aos/dist/aos.css';

//mixins
import {dynamicHead} from '@/mixins/dynamic-head.js'

export default {
  name: "about",
  mixins: [dynamicHead],
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchPage(this.$route.name).then(()=>{
          this.setParams()
          this.setMeta()
        }).catch(error=>{
          console.log(error)
        })
      }
    },
    '$store.getters.translation': {
      immediate: true,
      handler(newVal) {
        this.crumbs[0].title = newVal.mainLabel
        this.crumbs[1].title = newVal.aboutBrand
      }
    },
  },
  data(){
    return{
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
      crumbs: [
        {name: 'home', title: this.$t('mainLabel'), slug: ''},
        {name: 'about', title: this.$t('aboutBrand'), slug: ''}
      ],
    }
  },
  created() {
    this.fetchPage(this.$route.name).then(()=>{
      this.setParams()
      this.setMeta()
    }).catch(error=>{
      console.log(error)
    })
  },
  computed:{
    ...mapGetters({
      content: 'meta/meta'
    }),

  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });

  },
  methods:{
    ...mapActions({
      fetchPage: 'meta/GET_META'
    }),
    goBack(){

    },
    setParams(){
      this.params.title = this.content.metaTitle;
      this.params.description = this.content.metaDescription;
      this.params.keywords = this.content.metaKeywords;
      this.params.image = this.content.metaImage;
    }
  }
}